import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["rating"];
  static values = {
    rating: { type: Number, default: 0 },
  };

  connect() {
    for (let i = 1, len = 5; i <= len; i++) {
      if (i <= this.ratingValue) {
        this.ratingTarget.classList.add(`review-form-rating--${i}`);
      } else {
        this.ratingTarget.classList.remove(`review-form-rating--${i}`);
      }
    }
  }

  rate(e) {
    for (let i = 1, len = 5; i <= len; i++) {
      if (i <= e.params.rating) {
        this.ratingTarget.classList.add(`review-form-rating--${i}`);
      } else {
        this.ratingTarget.classList.remove(`review-form-rating--${i}`);
      }
    }
  }
}
