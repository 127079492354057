import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sidebar", "button"];
  static values = { serviceId: String };

  disconnect() {
    const sidebar = document.getElementById("directory-sidebar");
    if (sidebar) sidebar.classList.remove("directory-sidebar--active");
    if (this.hasSidebarTarget)
      this.sidebarTarget.classList.remove("directory-sidebar--active");
  }

  kindChange(e) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (e.target.value) {
      urlParams.set("k", e.target.value);
    } else {
      urlParams.delete("k");
    }
    window.Turbo.visit(`/directorio?${urlParams}`);
  }

  toggleFilters(e) {
    e.preventDefault();
    if (this.hasSidebarTarget)
      this.sidebarTarget.classList.remove("directory-sidebar--active");
  }

  search() {
    this.buttonTarget.click();
  }

  landingSearch(e) {
    window.Turbo.visit(`/contratar/${this.serviceIdValue}/${e.target.value}`);
  }

  toggleSidebar(e) {
    e.preventDefault();

    const sidebar = document.getElementById("directory-sidebar");
    if (sidebar.classList.contains("directory-sidebar--active")) {
      sidebar.classList.remove("directory-sidebar--active");
    } else {
      sidebar.classList.add("directory-sidebar--active");
    }
  }
}
