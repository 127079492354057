import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    this.onInputBlur = this.onInputBlur.bind(this);
  }

  focus(e) {
    e.preventDefault();
    const searchField = document.querySelector(".search__field");

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    setTimeout(() => {
      if (searchOverlay && searchField) {
        searchField.focus();
        searchField.addEventListener("blur-sm", this.onInputBlur);
        searchOverlay.classList.add("active");
      }
    }, 200);
  }

  onInputBlur(event) {
    event.preventDefault();
    const searchField = document.querySelector(".search__field");
    if (searchOverlay) {
      searchOverlay.classList.remove("active");
      searchField.removeEventListener("blur-sm", this.onInputBlur);
    }
  }
}
